import { Heading, Image, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { useCheckMobile } from "../../utils/CheckMobile";



export const MagazineForCompanies = () => {
    const isMobile = useCheckMobile();

    return (
        <VStack
            alignItems="center"
            justifyContent="center"
            w="100%"
            spacing={0}
            bgColo={"#e2e2e2"}
        >
            <NavBar />
            <Stack
                width="100%"
            >
                <Stack
                    direction="column"
                    bgGradient="linear(to-t, #D82B60, #FFA800)"
                    height="300px"
                    paddingTop="150px !important"
                    width="100%"
                    id="top"
                >
                    <Heading
                        color="white"
                        fontFamily="Chivo, sans-serif"
                        fontWeight="bold"
                        fontSize={isMobile ? "30pt" : "40pt"}
                        textAlign="center"
                        mb="40px"
                    >
                        REVISTA PARTIU PARA EMPRESAS
                    </Heading>
                </Stack>
                <Stack
                    direction="column"
                    justify={"center"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                >
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"100px"}>
                        <b>Leve a experiência de leitura digital da sua marca para o próximo nível! </b>
                        <br/>
                        A Partiu constrói revistas personalizadas com identidade visual da sua empresa. Nela é possível personalizar os conteúdos. Já imaginou reportagem para seru funcionários, condôminos, associados ou clientes? É isso que podemos criar para você e muito mais.
                    </Text>
                    <Image
                        src="https://static.partiu.com.br/revista-banner-2.png"
                    />
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"100px"}>
                        Com essas ofertas exclusivas, seus clientes poderão aproveitar promoções especiais, garantindo economia significativa em cada compra. Além de fortalecer o vínculo com sua marca, você oferece a eles uma experiência de consumo mais vantajosa e acessível. Transforme a forma como seus clientes economizam e fidelize seu público com benefícios reais!
                    </Text>
                    <Image
                        src="https://static.partiu.com.br/revista-banner-4.png"
                    />
                </Stack>
            </Stack>
            <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                    w="100%"
                    pt={isMobile ? "200px" : "200px"}
                >
                    
                    <Link
                        rel="noreferrer"
                        href="https://app.partiu.com.br/contato"
                        target="_blank"
                        _hover={{ textDecoration: "none" }}
                    >
                        <Stack
                            flexDir="row"
                            alignItems="center"
                            justifyContent="center"
                            color="white"
                            bgColor="#fc195a"
                            h="60px"
                            p="10px"
                            whiteSpace="normal"
                            text-align="center"
                            fontWeight="semibold"
                            lineHeight="20px"
                            fontSize={{ base: "15px", lg: "17px" }}
                            borderRadius="lg"
                            _hover={{
                                boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
                                transform: "translateY(-5px)",
                            }}
                        >
                            <Text
                                fontSize="14pt"
                            >
                                Quero entrar em contato
                            </Text>
                        </Stack>
                    </Link>
                </Stack>
            {/* <Stack
                direction="column"
                w="100%"
            >
                <Heading
                    color="#fc195a"
                    fontFamily="Chivo, sans-serif"
                    fontWeight="bold"
                    fontSize={isMobile ? "15pt" : "30pt"}
                    textAlign="center"
                    mb="40px"
                    mt={isMobile ? "0px" : "200pt"}
                >
                    OUTRAS EDIÇÕES
                </Heading>
                <Stack
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    spacing="0"
                    flexWrap="wrap"
                    gap="10px"
                >
                    {
                        MockRevistaData.map(({datetime, downloadUrl}) => (
                            <Card
                                isMobile={isMobile}
                                datetime={datetime}
                                downloadUrl={downloadUrl}
                            />
                        ))
                    }
                </Stack>
            </Stack> */}
            <Footer />
        </VStack>
    )
}