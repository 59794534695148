import { Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

function Logo() {
  return (
    <Box
      w="150px"
      h="35px"
      position={{ base: "relative", lg: "relative" }}
      left="0px"
      right="0px"
      margin="auto"
    >
      <Image
        src="https://static.partiu.com.br/logo-revista-branco.png"
        alt="Logo da Partiu"
        layout="fill"
        height="35px"
        priority
        objectFit="contain"
        objectPosition="left"
      />
    </Box>
  );
}

export default Logo;
