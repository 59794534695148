export const planDetail = {
  bronze: {
    title:"Bronze",
    items:[
        "1 acesso",
        "Revista digital de descontos",
        "+ de 2000 descontos em lojas próximas de você",
        "Mais de 300 descontos em lojas online" 
    ],
    amount: 39.9,
    url: '/revista/checkout?level=bronze'
  },
  prata: {
    title:"Prata",
    items:[
        "2 acessos",
        "Revista digital de descontos",
        "+ de 2000 descontos em lojas próximas de você",
        "Mais de 300 descontos em lojas online" 
    ],
    amount: 59.9,
    url: '/revista/checkout?level=prata'
  },
  ouro: {
    title:"Ouro",
    items:[
        "3 acesso",
        "Revista digital de descontos",
        "+ de 2000 descontos em lojas próximas de você",
        "Mais de 300 descontos em lojas online" 
    ],
    amount: 79.9,
    url: '/revista/checkout?level=ouro'
  }
}