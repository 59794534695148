import { Image } from "@chakra-ui/image";
import { Divider, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import { Box, Button, Checkbox, Container, FormControl, FormLabel, IconButton, Input, Spinner, useToast } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import Cards from 'react-credit-cards-2';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { NavBar } from "../../components/organisms/Header";
import { Title } from "../../components/atoms/Title";
import axios from "axios";
import { planDetail} from "./plan";
import { numberToBRL } from "../../utils/CurrencyToBr";


export const MagazinePurchase: React.FC = () => {

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const level = searchParams.get("level");

  console.log(level, search, search)

  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();


  const [acceptedRules, setAcceptedRules] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    cpf: '',
    email: ''
  });
  const [creditCard, setCreditCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
    companyCode: ''
  });

  const createBillMutation = useMutation(
    (data) => axios.post("https://api.partiu.com.br/api/v2/magazine/buy-magazine-and-access/", data), {
      onSuccess: () => {
        toast({
          title: "Pedido realizado com sucesso!",
          description: "Tenha um bom filme. Aproveite!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries("my-vouchers");
        history.push('/revista/purchase/success')
      },
      onError: (error) => {
        toast({
          title: "Erro ao realizar o pedido",
          description: `Houve um erro ao efetuar o seu pedido.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  );

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setCreditCard((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setCreditCard((prev) => ({ ...prev, focus: evt.target.name }));
  }

  const handleButtonClick = () => {
    createBillMutation.mutate({
      full_name: formData.fullName,
      email: formData.email,
      cpf: formData.cpf,
      level,
      credit_card: {
        holder_name: creditCard.name,
        card_expiration: creditCard.expiry,
        card_number: creditCard.number.replaceAll(" ", ""),
        card_cvv: creditCard.cvc,
        payment_method_code: "credit_card",
        payment_company_code:  creditCard.companyCode
      },
    });
  };


  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      w="100%"
      spacing={0}
      bgColo={"#e2e2e2"}
    >
      <NavBar />
      <Container maxWidth={"1100px"} mx="80px" marginY="24px">
        <VStack align="flex-start" width="100%" padding="10px 0px" backgroundColor={"white"} borderRadius={"10px"} marginTop={"40px"}>
          <VStack padding={"16px"}>
            <Title>
              {planDetail[level].title}
            </Title>
          </VStack>
          <Divider />
          <VStack padding="20px" alignItems={"flex-start"} 
            width={"100%"}>
            <HStack 
              display={"flex"} 
              flexDirection={{
                base: 'column',
                md: 'row',
              }}
              width={"100%"}
            >
              <Box maxWidth={"500px"} marginBottom={{base: "24px", md: 0}}>
                <Text fontSize={14}><b>Regulamento</b>:</Text>
                <Text textAlign="left" fontSize={14}>{
                  planDetail[level].items.map(item => (
                    <>
                    {item}
                    <br />
                    </>
                  ))
                }</Text>
              </Box>
              <HStack  
                alignItems={"center"} 
                justifyContent={"space-between"} 
                width="100%"
                >
                <Box>
                  <Text fontSize={"3xl"}>{numberToBRL(planDetail[level].amount)}</Text>
                </Box>
              </HStack>
            </HStack>
          </VStack>            
        </VStack>
        {/* <VStack 
          align="flex-start" 
          width="100%" 
          backgroundColor={"white"} 
          borderRadius={"10px"} 
          marginTop={"40px"} 
          padding={"16px"}
          >
          <Box width="100%" >
              <Title>Resumo do Pedido</Title>
              <Divider marginY={"16px"} />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">{`Ingressos (${quantity})`}</Text>
                <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">{numberToBRL(promotion.original_price)}</Text>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">Descontos</Text>
                <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">- {numberToBRL(quantity * (Number(promotion.original_price) - Number(promotion.sale_price)))}</Text>
              </Box>
              <Divider marginY={"16px"} />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Text fontSize={"1xl"} fontWeight={"bold"} color="#777">Total</Text>
                <Text fontSize={"2xl"} fontWeight={"bold"} color="green">{numberToBRL(quantity * Number(promotion.sale_price))}</Text>
              </Box>
            </Box>
        </VStack> */}
        <VStack align="flex-start" width="100%" padding="10px 0px" backgroundColor={"white"} borderRadius={"10px"} marginTop={"40px"}>
          <VStack padding={"16px"}>
            <Title>
              Dados do pagamento
            </Title>
          </VStack>
          <Divider />
          <HStack padding="20px" justifyContent={"space-between"} width={"100%"} flexDirection={{
                base: 'column',
                md: 'row',
              }}>
            <Box maxWidth={"400px"} marginBottom={"16px"}>
              <Input
                name="fullName"
                placeholder="Nome Completo"
                value={formData.fullName}
                onChange={(e) => setFormData({
                  ...formData,
                  fullName: e.target.value
                })}
              />
              <Input
                marginTop={"8px"}
                name="cpf"
                placeholder="CPF"
                value={formData.cpf}
                onChange={(e) => setFormData({
                  ...formData,
                  cpf: e.target.value
                })}
                mask="999.999.999-99"
                maskChar={"-"} 
                as={ReactInputMask}
              />
               <Input
                marginTop={"8px"}
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => setFormData({
                  ...formData,
                  email: e.target.value
                })}
              />
              <Input
                marginTop={"8px"}
                name="number"
                placeholder="Número do Cartão"
                value={creditCard.number}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                mask="9999999999999999"
                maskChar={"-"} 
                as={ReactInputMask}
              />
              <Input
                marginTop={"8px"}
                type="text"
                name="name"
                placeholder="Nome no cartão"
                value={creditCard.name}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              <Input
                marginTop={"8px"}
                type="text"
                name="expiry"
                placeholder="Data de vencimento"
                value={creditCard.expiry}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                mask="99/9999"
                maskChar={"-"} 
                as={ReactInputMask}
              />
              <Input
                marginTop={"8px"}
                name="cvc"
                placeholder="CVC"
                value={creditCard.cvc}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                mask="999"
                maskChar={"-"} 
                as={ReactInputMask}
              />
              
              {createBillMutation.isError && (
                <Box marginTop={"16px"} color="red">
                  {createBillMutation.error.response.data.error}
                </Box>  
              )}
            </Box>
            <Box>
              <Cards
                number={creditCard.number}
                expiry={creditCard.expiry}
                cvc={creditCard.cvc}
                name={creditCard.name}
                focused={creditCard.focus}
                callback={(params) => {
                  if (typeof params === 'object' && params && params.issuer !== "unknown"){
                    if (params.issuer && creditCard.companyCode.toLowerCase() !== params.issuer.toLowerCase() && params.issuer.toLowerCase() !== "unknown") {
                      setCreditCard((prevState) => ({
                        ...prevState,
                        companyCode: params.issuer
                      }))
                    }

                  }
                }}  
              />
            </Box>
          </HStack>
          
        </VStack>
        <HStack paddingTop="20px" align="center" marginBottom={"16px"}>
          <Checkbox margin={0} onChange={(e) => setAcceptedRules(e.target.checked)} />
          <Text fontSize={14}><b>Li o regulamento e estou de acordo.</b></Text>
        </HStack>
        <HStack marginBottom={"48px"}>
          <Button onClick={() => history.goBack()} width="100%" colorScheme="gray">
            Voltar
          </Button>
          <Button
            width="100%"
            disabled={!acceptedRules || createBillMutation.isLoading} 
            colorScheme="green"
            onClick={handleButtonClick}
            isLoading={createBillMutation.isLoading}
          >
            Comprar
          </Button>
        </HStack>
      </Container>
    </VStack>
  );
};
